import createReactComponent from '../createReactComponent';
export default createReactComponent('database-import', 'IconDatabaseImport', [
  ['path', { d: 'M12 6m-8 0a8 3 0 1 0 16 0a8 3 0 1 0 -16 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M4 6v8m5.009 .783c.924 .14 1.933 .217 2.991 .217c4.418 0 8 -1.343 8 -3v-6',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M11.252 20.987c.246 .009 .496 .013 .748 .013c4.418 0 8 -1.343 8 -3v-6m-18 7h7m-3 -3l3 3l-3 3',
      key: 'svg-2',
    },
  ],
]);
